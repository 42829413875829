<template>
  <div class="prompt">
    <div class="prompt__inner card">
      <svg v-if="icon === 'success'" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
        <g fill="#14D066" fill-rule="nonzero">
          <path
            d="M40.8 20.16a1.2 1.2 0 1 1 2.4 0V22A21.2 21.2 0 1 1 30.628 2.625a1.2 1.2 0 0 1-.976 2.192A18.8 18.8 0 1 0 40.8 22v-1.84z"
          />
          <path
            d="M41.151 5.152a1.2 1.2 0 0 1 1.698 1.696l-20 20.02a1.2 1.2 0 0 1-1.698 0l-6-6a1.2 1.2 0 1 1 1.698-1.697l5.15 5.152L41.152 5.152z"
          />
        </g>
      </svg>

      <h2 class="title title--small gutter--half">
        {{ title }}
      </h2>
      <p class="paragraph">
        {{ text }}
      </p>

      <slot />

      <div class="prompt__actions">
        <button v-if="cancelText" class="button button--large button--white" @click="$emit('onFalse')">
          {{ cancelText }}
        </button>
        <button v-if="ctaText" class="button button--blue button--large" @click="$emit('onTrue')">
          {{ ctaText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Prompt",

  props: {
    text: {
      type: String,
      default: "",
    },
    ctaText: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    cancelText: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.prompt_inner {
  min-width: 200px;
}
</style>
